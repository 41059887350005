#css-demos {
  > .image img {
    width: 8em;
    height: 8em;
  }

  #demo-container {
    color: #000;
    background-color: #FFF;

    #ssl-tests {
      display: block;

      & > .button {
        margin: 1em;
      }
    }

    #ssl-test-result, #supported {
      word-wrap: break-word;
    }
  }
}

#css-demo-zoom,
#css-demo-rotate {
  text-align: center;

  > .container {
    display: inline-block;
    text-align: center;
  }
}

#css-demo-zoom {

  > .image img {
    width: 4em;
    height: 4em;
  }

  #demo-img-zoom-40 {
    transform: scale(0.4, 0.4);
    -webkit-transform: scale(0.4, 0.4);
  }

  #demo-img-zoom-80 {
    transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
  }

  #demo-img-zoom-120 {
    padding: 7px;
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
  }

  #demo-img-zoom-160 {
    padding: 22px;
    transform: scale(1.6, 1.6);
    -webkit-transform: scale(1.6, 1.6);
  }

  #demo-img-zoom-200 {
    padding: 36px;
    transform: scale(2, 2);
    -webkit-transform: scale(2, 2);
  }
}

#demo-img-rotate-90 {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

#demo-img-rotate-180 {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

#demo-img-rotate-270 {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}

#demo-img-rotate-270 {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}

#car-drive {
  text-align: center;
  animation: drive 3s infinite;
  -webkit-animation: webkit-drive 3s infinite;
}

#keycode, #betas {
  #keycode-container, #betas-container {
    &> .label {
      display: block;
      font-size: 2em;
      padding-bottom: 0.3em;
    }
  }
}

@keyframes drive {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(200px);
  }
}

@-webkit-keyframes webkit-drive {
  0% {
    -webkit-transform: translateX(-200px);
  }
  100% {
    -webkit-transform: translateX(200px);
  }
}