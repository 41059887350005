@font-face {
  font-family: 'Roboto';
  src: url("web-fonts/roboto_medium/Roboto-Medium-webfont.eot");
  src: url("web-fonts/roboto_medium/Roboto-Medium-webfont.eot?#iefix") format("embedded-opentype"), 
    url("web-fonts/roboto_medium/Roboto-Medium-webfont.woff2") format("woff2"), 
    url("web-fonts/roboto_medium/Roboto-Medium-webfont.woff") format("woff"), 
    url("web-fonts/roboto_medium/Roboto-Medium-webfont.ttf") format("truetype"), 
    url("web-fonts/roboto_medium/Roboto-Medium-webfont.svg#robotobold") format("svg");
  font-weight: bold;
  font-style: normal; 
}

/*
@font-face {
  font-family: 'Roboto';
  src: url("web-fonts/roboto_bolditalic/Roboto-BoldItalic-webfont.eot");
  src: url("web-fonts/roboto_bolditalic/Roboto-BoldItalic-webfont.eot?#iefix") format("embedded-opentype"), 
    url("web-fonts/roboto_bolditalic/Roboto-BoldItalic-webfont.woff2") format("woff2"), 
    url("web-fonts/roboto_bolditalic/Roboto-BoldItalic-webfont.woff") format("woff"), 
    url("web-fonts/roboto_bolditalic/Roboto-BoldItalic-webfont.ttf") format("truetype"), 
    url("web-fonts/roboto_bolditalic/Roboto-BoldItalic-webfont.svg#robotobold_italic") format("svg");
  font-weight: bold;
  font-style: italic, oblique; 
}
*/

@font-face {
  font-family: 'Roboto';
  src: url("web-fonts/roboto_lightitalic/Roboto-LightItalic-webfont.eot");
  src: url("web-fonts/roboto_lightitalic/Roboto-LightItalic-webfont.eot?#iefix") format("embedded-opentype"), 
    url("web-fonts/roboto_lightitalic/Roboto-LightItalic-webfont.woff2") format("woff2"), 
    url("web-fonts/roboto_lightitalic/Roboto-LightItalic-webfont.woff") format("woff"), 
    url("web-fonts/roboto_lightitalic/Roboto-LightItalic-webfont.ttf") format("truetype"), 
    url("web-fonts/roboto_lightitalic/Roboto-LightItalic-webfont.svg#robotolightitalic") format("svg");
  font-weight: normal;
  font-style: italic, oblique; 
}

@font-face {
  font-family: 'Roboto';
  src: url("web-fonts/roboto_light/Roboto-Light-webfont.eot");
  src: url("web-fonts/roboto_light/Roboto-Light-webfont.eot?#iefix") format("embedded-opentype"), 
    url("web-fonts/roboto_light/Roboto-Light-webfont.woff2") format("woff2"), 
    url("web-fonts/roboto_light/Roboto-Light-webfont.woff") format("woff"), 
    url("web-fonts/roboto_light/Roboto-Light-webfont.ttf") format("truetype"), 
    url("web-fonts/roboto_light/Roboto-Light-webfont.svg#robotolight") format("svg");
  font-weight: normal;
  font-style: normal; 
}