@import 'widgets/widgets';

@import './demo/demo';
@import './settings/settings';
@import './senders/senders';
@import './connect/connect';
@import './tutorial/tutorial';
@import './help/help';
@import './connected/connected';
@import './video-player/video-player';
@import './image-player/image-player';