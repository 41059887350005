$wvcWhite: #FFFFFF;
$wvcAlmostWhite: #F9F9F9;
$wvcBlack: #000000;
$wvcAlmostBlack: #202020;
$wvcLightGrey: #EFEFEF;
$wvcMedGrey: #CCCCCC;
$wvcDarkGrey: #414141;


$wvcBlue: #2196f3;
$wvcBlue-dark: #002D61;
$wvcYellow: #ffc106;
$wvcYellow-dark: #FF9F00;
$wvcRed: #fa827e;

$shadowColor: rgba(0, 0, 0, 0.2);
$shadowColor-dark: rgba(220, 220, 220, 0.2);

$textColor: $wvcAlmostBlack;
$textColor-dark: $wvcAlmostWhite;

$backgroundColor: $wvcBlue;
$backgroundColor-dark: $wvcBlack;