#app .button.horizontalslider {
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 50%;
  height: 0.5em;
  animation: slider-focus-off 1s;
  -webkit-animation: webkit-slider-focus-off 1s;
  margin-bottom: 0;
  vertical-align: middle;
  transform: scale(1);

  &.focus {
    box-shadow: 0 0 1em 0.2em #FFF;
    animation: slider-focus-on 1s;
    -webkit-animation: webkit-slider-focus-on 1s;
  }

  .scrubbarbuffer {
    position: absolute;
    left: 0;
    top: 0;
    width: 1em;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
  }

  .horizontalsliderleft {
    position: absolute;
    left: 0;
    top: 0;
    width: 1em;
    height: 100%;
    background-color: rgb(255, 255, 255);
  }

  .horizontalsliderhandle {
    position: absolute;
    left: 0;
    top: -0.8em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
  }

  @keyframes slider-focus-off {
    0% {
      box-shadow: 0 0 1em 0.2em rgba(255, 255, 255, 1);
    }
    100% {
      box-shadow: 0 0 1em 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes slider-focus-on {
    0% {
      box-shadow: 0 0 1em 0 rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 0 0 1em 0.2em rgba(255, 255, 255, 1);
    }
  }
  @-webkit-keyframes webkit-slider-focus-off {
    0% {
      box-shadow: 0 0 1em 0.2em rgba(255, 255, 255, 1);
    }
    100% {
      box-shadow: 0 0 1em 0 rgba(255, 255, 255, 0);
    }
  }
  @-webkit-keyframes webkit-slider-focus-on {
    0% {
      box-shadow: 0 0 1em 0 rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 0 0 1em 0.2em rgba(255, 255, 255, 1);
    }
  }
}