.loading-bars {
  display: inline-block;
  position: relative;
  width: 5.3em;
  height: 5.3em;
}
.loading-bars div {
  display: inline-block;
  position: absolute;
  left: 0.5em;
  width: 1em;
  background: #fff;
  animation: loading-bars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  -webkit-animation: webkit-loading-bars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading-bars div:nth-child(1) {
  left: 0.5em;
  animation-delay: -0.24s;
  -webkit-animation-delay: -0.24s;
}
.loading-bars div:nth-child(2) {
  left: 2.15em;
  animation-delay: -0.12s;
  -webkit-animation-delay: -0.12s;
}
.loading-bars div:nth-child(3) {
  left: 3.75em;
  animation-delay: 0;
  -webkit-animation-delay: 0;
}

@keyframes loading-bars {
  0% {
    top: 0.5em;
    height: 4.25em;
  }
  50%, 100% {
    top: 1.6em;
    height: 2.2em;
  }
}

@-webkit-keyframes webkit-loading-bars {
  0% {
    top: 0.5em;
    height: 4.25em;
  }
  50%, 100% {
    top: 1.6em;
    height: 2.2em;
  }
}