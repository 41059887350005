
.setting-switch {
    text-align: left;;

    .horizontallist {
        display: inline-block;
        margin-bottom: 0.5em;
        margin-left: 5em;

        .label {
            padding-right: 1.5em;
            vertical-align: middle;
        }
        .switch {
            margin-right: 0.5em;
            vertical-align: middle;
        }
    }
}