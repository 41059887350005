@import "colors";
@import "card";

$buttonTextColor: $wvcAlmostWhite;

$buttonFocused: $wvcYellow;
$buttonFocused-dark: $wvcYellow-dark;
$buttonUnfocused: $wvcBlue;
$buttonUnfocused-dark: $wvcBlue-dark;

$linkTextFocused: $buttonTextColor;
$linkTextFocused-dark: $buttonTextColor;
$linkFocused: $buttonFocused;
$linkFocused-dark: $buttonFocused-dark;
$linkTextUnfocused: $textColor;
$linkTextUnfocused-dark: $textColor-dark;
$linkUnfocused: $cardBackgroundColor;
$linkUnfocused-dark: $cardBackgroundColor-dark;

.button {
    background-color: $buttonUnfocused;
    box-shadow: 0px 0.25em 0.58em $shadowColor;
    border-radius: 0.17em;
    border: 0;
    outline: none;
    display: block;
    max-width: 50em;
    margin: 0 auto 1em auto;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -webkit-tap-highlight-color: transparent;

    >div {
        outline: none;

        >img {
            outline: none;
        }
    }

    .label {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        color: $buttonTextColor;
        line-height: 1.5em;
        padding: 0.5em;
        display: block;
        transition: 0.4s;
        -webkit-transition: 0.4s;
    }

    &.linkButton {
        .label {
            text-decoration: underline
        }

        &.buttonFocussed {
            background-color: $linkFocused;
            transform: scale(1.1);

            .label {
                color: $linkTextFocused;
            }
        }

        &.buttonBlurred {
            background-color: $linkUnfocused;
            box-shadow: none;
            transform: scale(1);

            .label {
                color: $linkTextUnfocused;
            }
        }
    }
}

.buttonFocussed {
    background-color: $buttonFocused;
    transform: scale(1.1);
}

.buttonBlurred {
    transform: scale(1);
}

.buttonFocussed.menuendpoint {
    background-color: $buttonFocused;
}

.dark .button {
    background-color: $buttonUnfocused-dark;
    box-shadow: 0px 0.25em 0.58em $shadowColor-dark;

    &.linkButton {
        &.buttonFocussed {
            background-color: $linkFocused-dark;

            .label {
                color: $linkTextFocused-dark;
            }
        }
        &.buttonBlurred {
            background-color: $linkUnfocused-dark;

            .label {
                color: $linkTextUnfocused-dark;
            }
        }
    }

    &.buttonFocussed {
        background-color: $buttonFocused-dark;
    }
}