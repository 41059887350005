#help {

  #help-container {
    position: relative;
  }

  #go-back {
      margin-top: 1em;
      margin-bottom: 0;
  }

  #screenshot-container .container.image {
      display: inline-block;

      &#phone-code {
          margin-right: 0.8em;
      }
  }

  #connect-circled_img{
    width: 25em;
    height: 5.2em;
  }

  #cast-to-smart-tv_img {
    width: 13em;
    height: 11em;
  }

  #phone-code_img {
      width: 6.2em;
      height: 11em;
  }

  #enter-code_img {
      width: 16em;
      height: 11em;
  }

  #top-panel {
    border-bottom: grey;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  #top-left-panel, #bottom-left-panel {
    border-right: grey;
    border-right-width: 1px;
    border-right-style: solid;
  }

  #top-panel, #bottom-panel {
      white-space: nowrap;
      height: 17em;
  }

  #top-panel > .container, 
  #bottom-panel > .container {
    display: inline-block;
    width: 30em;
    vertical-align: top;
    height: 17em;

    > .label {
      font-size: 1.8em;
      font-weight: bold;
      padding-bottom: 0.4em;
      display: block;
      white-space: normal;

        &#android-label, &#ios-label {
          font-size: 1em;
          font-weight: normal;
        }
    }
  }

  #top-left-title {
      display: block;
  }

  #bottom-left-title, #bottom-right-title {
      padding-top: 0.4em;
  }

  #app-info {
    display: inline-block;
    margin-left: 1.2em;
    margin-right: 1.2em;
    vertical-align: top;

    > .label {
        display: block;
    }

    #app-logo {
      padding-bottom: 1em;
      #app-logo_img {
        width: 5.1em;
        height: 5.1em;
      }
    }

    #app-name, #or-visit {
        padding-bottom: 0.5em;
    }

    #url {
        font-size: 0.8em;
    }
  }

  #android-container, #ios-container {
    display: inline-block;
  }

}