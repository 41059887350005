@import "colors";

$cardBackgroundColor: $wvcWhite;
$cardBackgroundColor-dark: $wvcBlack;

$cardShadow: $shadowColor;

.card-container {
    background-color: $backgroundColor;
    width: 100%;
    height: 100%;
    transition: 0.4s;
    -webkit-transition: 0.4s;

    .card {
        margin-left: auto;
        margin-right: auto;
        max-width: 70em;
        color: $textColor;
        background-color: $cardBackgroundColor;
        box-shadow: 0px 0.25em 1.16em $cardShadow;
        border-radius: 0.17em;
        overflow-y: auto;
        overflow-x: hidden;
        transition: 0.4s;
        -webkit-transition: 0.4s;

        margin: 1.5em;
        padding: 2em;
        display: inline-block;
        min-width: 60em;
    }
}

.dark .card-container {
    background-color: $cardBackgroundColor-dark;

    .card {
        color: $textColor-dark;
        background-color: $cardBackgroundColor-dark;
        box-shadow: none;
        border-radius: 0;
    }
}