#logging {
  #back-button {
    margin-top: 2em;
  }

  .horizontallist {
    > .button {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }
}