.controls-active {
  #subtitles-container {
    bottom: 11.5em;
  }
}

#subtitles-container {
  bottom: 0.5em;
  position: absolute;
  left: 0;
  right: 0;

  &.sub-line-0 {
    bottom: auto;
    top: 0;
  }

  &.sub-line-10 {
    bottom: auto;
    top: 10%;
  }

  &.sub-line-20 {
    bottom: auto;
    top: 20%;
  }

  &.sub-line-30 {
    bottom: auto;
    top: 30%;
  }

  &.sub-line-40 {
    bottom: auto;
    top: 40%;
  }

  &.sub-line-50 {
    bottom: auto;
    top: 50%;
  }

  &.sub-line-60 {
    bottom: 40%;
  }

  &.sub-line-70 {
    bottom: 30%;
  }

  &.sub-line-80 {
    bottom: 20%;
  }

  &.sub-line-90 {
    bottom: 10%;
  }

  &.sub-line-100 {
    bottom: 0;
  }

  &.sub-position-0 {
    left: 0;
    right: auto;
  }

  &.sub-position-10 {
    left: 10%;
    right: auto;
  }
  
  &.sub-position-20 {
    left: 20%;
    right: auto;
  }
  
  &.sub-position-30 {
    left: 30%;
    right: auto;
  }
  
  &.sub-position-40 {
    left: 40%;
    right: auto;
  }
  
  &.sub-position-50 {
    left: 0;
    right: 0;
  }
  
  &.sub-position-60 {
    left: auto;
    right: 40%;
  }
  
  &.sub-position-70 {
    left: auto;
    right: 30%;
  }
  
  &.sub-position-80 {
    left: auto;
    right: 20%;
  }
  
  &.sub-position-90 {
    left: auto;
    right: 10%;
  }
  
  &.sub-position-100 {
    left: auto;
    right: 0;
  }

  #subtitles {
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    padding: 0.7em;
    font-size: 1.8em;

    &.subtitles-empty {
      display: none;
    }

    & > span {
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      white-space: pre-wrap;
      display: block;
    }
  }

}