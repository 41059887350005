$duration: 3s;

.scanning {
  display: inline-block;
  position: relative;

  div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  div:nth-child(1) {
    background-image: url('components/widgets/scanning/images/scan-1.png');
    animation: none;
  }

  div:nth-child(2) {
    animation: scanning-anim-2 $duration infinite;
    -webkit-animation: webkit-scanning-anim-2 $duration infinite;
    opacity: 0;
    background-image: url('components/widgets/scanning/images/scan-2.png');
  }

  div:nth-child(3) {
    animation: scanning-anim-3 $duration infinite;
    -webkit-animation: webkit-scanning-anim-3 $duration infinite;
    opacity: 0;
    background-image: url('components/widgets/scanning/images/scan-3.png');
  }

}

@keyframes scanning-anim-2 {
  0% {
    opacity: 0;
  }
  25%, 75% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }
}

@keyframes scanning-anim-3 {
  0%, 25% {
    opacity: 0;
  }
  50% {
    opacity: 1
  }
  75%, 100% {
    opacity: 0;
  }
}

@-webkit-keyframes webkit-scanning-anim-2 {
  0% {
    opacity: 0;
  }
  25%, 75% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes webkit-scanning-anim-3 {
  0%, 25% {
    opacity: 0;
  }
  50% {
    opacity: 1
  }
  75%, 100% {
    opacity: 0;
  }
}

.dark .scanning {
  div:nth-child(1) {
    background-image: url('components/widgets/scanning/images/scan-1-dark.png');
  }

  div:nth-child(2) {
    background-image: url('components/widgets/scanning/images/scan-2-dark.png');
  }

  div:nth-child(3) {
    background-image: url('components/widgets/scanning/images/scan-3-dark.png');
  }
}