#dark-switch {
    .switchSlider {
        background-image: url('components/senders/images/bg-light-mode.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &:before {
            background-image: url('components/senders/images/light-mode.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    &.switchOn .switchSlider {
        background-image: url('components/senders/images/bg-dark-mode.png');

        &:before {
            background-image: url('components/senders/images/dark-mode.png');
        }
    }
}