$keySize: 2.8em;
$fontSize: $keySize * (2/5);
$lineHeight: $keySize * (3/5);

#keyboard {
  width: $keySize * 11;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;

  .row {
    display: block;
    height: $keySize;
    white-space: nowrap;

    .keyboardButton {
      display: inline-block;
      width: $keySize;
      height: $keySize;
      margin: 0;

      .label {
        font-size: $fontSize;
        line-height: $lineHeight;
      }

      &.keyDEL {
        width: $keySize * 1.2;
      }
    }

    .spacer {
      display: inline-block;
      width: $keySize;
      height: 1px;
      margin: 0;

      &#keyboard_9_1 {
        width: $keySize/2;
      }
    }
  }

  #keyboard_row_2 {
    padding-left: $keySize/2;
  }
}