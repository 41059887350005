@import "../../../colors";

$inputBackgroundColor: $wvcLightGrey;
$inputBackgroundColor-dark: $wvcDarkGrey;

$placeholderColor: rgba(0, 0, 0, 0.2);
$placeholderColor-dark: rgba(220, 220, 220, 0.2);

$borderColor: $wvcBlue;
$borderColor-dark: $wvcBlue-dark;

.text-input {
  transition: border, box-shadow 0.3s ease;
  -webkit-transition: border, box-shadow 0.3s ease;
  color: $textColor;
  background-color: $inputBackgroundColor;
  border: none;
  box-sizing: border-box;
  padding-left: 0.5em;
  padding-right: 0.5em;
  outline: none;

  transition: border, box-shadow 0.3s ease;
  -webkit-transition: border, box-shadow 0.3s ease;
  border: 1px solid $borderColor;
  box-shadow: 0 0 5px $borderColor;

  &.placeholder {
    transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    color: rgba(0, 0, 0, 0.2);

    &::after {
      position: absolute;
      left: 50%;
    }
  }

  &::after {
    color: $textColor;
    animation: 1s blink step-end infinite;
    -webkit-animation: 1s webkit-blink step-end infinite;
    content: '|';
  }

  @keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: $textColor
    }
  }
  
  @-webkit-keyframes webkit-blink {
    from, to {
      color: transparent;
    }
    50% {
      color: $textColor;
    }
  }
}

.dark #app .text-input {
  color: $textColor-dark;
  background-color: $inputBackgroundColor-dark;
  border: 1px solid $borderColor-dark;
  box-shadow: 0 0 5px $borderColor-dark;

  &::after {
    color: $textColor-dark;
    animation: 1s blink-dark step-end infinite;
    -webkit-animation: 1s webkit-blink-dark step-end infinite;
  }

  @keyframes blink-dark {
    from, to {
      color: transparent;
    }
    50% {
      color: $textColor-dark;
    }
  }
  
  @-webkit-keyframes webkit-blink-dark {
    from, to {
      color: transparent;
    }
    50% {
      color: $textColor-dark;
    }
  }
}