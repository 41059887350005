#connected {
  background-color: #000;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(components/connected/splash-hd.png);
  background-position: center;
  position: relative;
  width: 100%;
  height: 100%;

  #back-button {
    margin: 2em;
    animation: none;
    -webkit-animation: none;
    
    &.buttonFocussed {
      transform: scale(1);
    }
  }

  #message {
    font-size: 2em;
    color: #FFF;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-bottom: 1em;
  }
}