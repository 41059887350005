@import './keyboard';
@import './loader';

$width: 32em;
$logoSize: 4em;

#connect {

  #tutorial-button {
    padding: 0.5em;
    position: absolute;
    right: 0;
    top: 0;
    height: 2.5em;
    z-index: 1000;
  }

  #logo-container {
    height: 4em;

    #busy-container {
      width: 4em;
      height: 4em;
      display: none;
    }

    &.loading #busy-container {
      display: inline-block;
    }

     #error, #success {
       display: none;
     }
    
    &.error #error {
      display: inline-block;
    }

    &.success #success {
      display: block;
    }

    #error {
      height: $logoSize;
      padding: 4px;

      #error-message {
        height: $logoSize * 0.25;
      }

      #error-img {
        margin-left : auto;
        margin-right: auto;

        img {
          padding: ($logoSize * 0.75) * 0.15;
          width: ($logoSize * 0.75) - ((($logoSize * 0.75) * 0.15) * 2);
          height: $logoSize * 0.75 - ((($logoSize * 0.75) * 0.15) * 2);
        }
      }
    }

    &.loading, &.error, &.success {
      .receiver-logo {
        display: none;
      }
    }

  }

  #enter-code {
    height: 2em;
    font-size: 2em;
    line-height: 2em;
    text-align: center;
    margin-top: 1em;
    max-width: $width / 2;
    margin-left: auto;
    margin-right: auto;
  }

  #connect-button {
    margin-top: 1em;
    max-width: $width;
  }

  #app-version {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #105098;
    padding: 0.2em;
    font-size: 1em;
  }
}

.dark #app #connect {
  #app-version {
    color: #828282;
  } 
}