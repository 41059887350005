$logoSize: 4em;

#app {
    .wvc-logo {
        padding: 4px;

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: $logoSize;
            height: $logoSize;
        }
    }

}