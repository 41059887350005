@import "../../colors";

$buttonBlurColor: $wvcAlmostWhite;
$buttonFocusColor: $wvcYellow;
$buttonFocusColor-dark: $wvcYellow-dark;
$iconBackgroundColor: $wvcMedGrey;

#app #senders #senders-list {
    max-width: 45em;

    .sender-button {
        width: 10em;
        height: 7.5em;
        margin: 1em;
        transform: 0.4s;

        &.buttonFocussed .sender-container .sender-label {
            color: $textColor;
        }

        .label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .sender-container {
            .sender-icon {
                margin-left: auto;
                margin-right: auto;
                display: block;
                background-color: $iconBackgroundColor;
                border-radius: 50%;
                width: 3em;
                height: 3em;
                margin-top: 0.5em;
                margin-bottom: 0.5em;
                padding: 0.5em;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .sender-label {
                margin-left: auto;
                margin-right: auto;
                display: block;
                color: #000;
                text-transform: none;
            }
        }


        &.buttonFocussed {
            background-color: $buttonFocusColor;
            //animation: sender-button-color 0.4s ease;
            //-webkit-animation: webkit-sender-button-color 0.4s ease;
            transform: scale(1.1);
        }

        &.buttonBlurred {
            background-color: $buttonBlurColor;
            //animation: sender-button-color-off 0.2s ease;
            //-webkit-animation: webkit-sender-button-color-off 0.2s ease;
            transform: scale(1);
        }

        @keyframes sender-button-color {
            from {
                background-color: $buttonBlurColor;
                transform: scale(1);
            }

            to {
                background-color: $buttonFocusColor;
                transform: scale(1.1);
            }
        }

        @keyframes sender-button-color-off {
            from {
                background-color: $buttonFocusColor;
                transform: scale(1.1);
            }

            to {
                background-color: $buttonBlurColor;
                transform: scale(1);
            }
        }

        @-webkit-keyframes webkit-sender-button-color {
            from {
                background-color: $buttonBlurColor;
                transform: scale(1);
            }

            to {
                background-color: $buttonFocusColor;
                transform: scale(1.1);
            }
        }

        @-webkit-keyframes webkit-sender-button-color-off {
            from {
                background-color: $buttonFocusColor;
                transform: scale(1.1);
            }

            to {
                background-color: $buttonBlurColor;
                transform: scale(1);
            }
        }
    }
}

.dark #app #senders #senders-list {
    .sender-button {
        &.buttonFocussed {
            background-color: $buttonFocusColor-dark; 
        }
    }
}