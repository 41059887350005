.loader {
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  text-indent: -9999em;
  border-top: 2px solid #2196F3 !important;
  border-right: 2px solid #2196F3 !important;
  border-bottom: 2px solid #2196F3 !important;
  border-left: 2px solid #EEEEEE !important;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}