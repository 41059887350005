@import "senders-list";
@import "dark-switch";

#app {
  #senders {

    #senders-top {
      padding-bottom: 1em;

      & > .listitem {
        vertical-align: top;
      }

      #senders-detected {
        min-width: 35em;
        margin-top: 2em;
      }
    }

    .heading {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-weight: bold;
      white-space: wrap;
    }

    #logo-section {
      padding-left: 6em;
      padding-right: 1em;
      padding-top: 1em;

      .receiver-logo img {
        width: 16em;
        height: 16em;
      }

      #dark-switch {
        display: inline-block;
        margin-right: 0.7em;
      }
    }
    
    #scanning-list, #scanning-stopped-list, #select-sender {
      font-size: 2em;
      vertical-align: top;
      margin-bottom: 1.5em;
    }

    #scanning-list, #scanning-stopped-list {
      .label {
        max-width: 15em;
      }

      .listitem {
        display: inline-block;
      }

      .scanning {
        width: 1em;
        height: 1em;
        margin-bottom: 0.5em;
      }

      #remote {
        width: 1.5em;
        height: 1.5em;

        background-image: url('components/senders/images/remote.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .scanning, #remote {
        vertical-align: top;
        margin-right: 0.5em;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    #scanning-stopped-list {
      display: none;
    }

    #app-version {
      position: absolute;
      bottom: 0;
      left: 0;
      color: #105098;
      padding: 0.2em;
      font-size: 1em;
    }

    #connect-manually {
      margin-top: 1em;
      max-width: 25em;
    }

    #select-sender {
      display: none;
      max-width: 20em;
    }

    &.senders-available {
      #select-sender {
        display: inline-block;
        margin-bottom: 0.5em;
      }

      #scanning-list, #scanning-stopped-list {
        margin-top: 1em;
        font-size: 1em;
        margin-bottom: 1em;

        .label {
          max-width: 40em;
        }
      }
    }

    &.scanning-active {
      #scanning-list {
        display: block;
      }

      #scanning-stopped-list {
        display: none;
      }
    }

    &.scanning-inactive {
      #scanning-list {
        display: none;
      }

      #scanning-stopped-list {
        display: block;
      }
    }
    
  }

}

.dark #app #senders {
  #app-version {
    color: #828282;
  } 


  #scanning-stopped-list {
    #remote {
      background-image: url('components/senders/images/remote-dark.png');
    }
  }
}