@import "loading";
@import "components/components";
@import "web-fonts/web-fonts";
@import "carousel";
@import "button";
@import "card";
@import "colors";


html {
  height: 100%;
}

body {
  //overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", sans-serif;
  font-weight: lighter;
  color: $textColor;
  background-color: $backgroundColor;
  transition: 0.4s;
  -webkit-transition: 0.4s;

  &.dark {
    color: $textColor-dark;
    background-color: $backgroundColor-dark;
  }
}

#analytics-frame {
  width: 1px;
  height: 1px;
  position: absolute;
  right: 0;
  bottom: 0;
  visibility: hidden;
  border: 0;
}

#log-box {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  font-family: monospace;
  background-color: rgba(0, 0, 0, .5);
  overflow: scroll;
  min-height: 25%;
  max-height: 75%;
  font-size: 14pt;
  word-break: break-word;
  pointer-events: none;

  &.hidden {
    display: none;
  }
  .log-debug {
    color: #c1c7c7;
  }
  .log-info {
    color: #80a7c1;
  }
  .log-warn {
    color: #bcc23a;
  }
  .log-error {
    color: $wvcRed
  }
}

#app {
  text-align: center;

  object {
    position: absolute;
  }
}

/* List widgets */
.horizontallist,
.verticallist {
  position: relative;
}

.horizontallist .listitem {
  display: inline-block;
}

/* nested lists - use #IDs instead for more complex layouts */
.verticallist .horizontallist .listitem {
  display: inline-block;
}

.verticallist .listitem {
  display: block;
  position: relative;
}

/* nested lists - use #IDs instead for more complex layouts */
.horizontallist .verticallist .listitem {
  display: block;
}

/* Horizontal carousel widgets */
/* the mask is the top level of the carousel and defines where it is displayed and how much is visible */
.horizontallistmask {
  overflow: hidden;
  width: 100%;
}

/* ensure nested carousels have correct formatting, you may need to target IDs in complex layouts */
.horizontallistmask .horizontallist .listitem {
  display: inline-block;
}

.horizontalcarousel {
  width: 99999px;
  /* needs to be big enough so carousel plus clones do not flow onto more then one line */
}

/* DEVICE SPECIFIC DEFAULTS EXAMPLE ------------------ */

/* if you target some consoles you may need to add additional styles like this */
.someConsoles .horizontallist .listitem {
  display: inline-block;
  float: left;
}

/* SAMPLEAPP BASE STYLES */

/* Core */

/* Maple needs initial element background in base stylesheet... not in dynamically loaded styles! */
/*
.samsung.layout540p #app {
    width: 960px;
    height: 540px;
    background-image: url(../img/bbc_background_540.png);
    background-repeat: no-repeat;
    background-color: red;
}

.samsung.layout720p #app {
    width: 1280px;
    height: 720px;
    background-image: url(../img/bbc_background_720.png);
    background-repeat: no-repeat;
}

.samsung.layout1080p #app {
    width: 1920px;
    height: 1080px;
    background-image: url(../img/bbc_background_1080.png);
    background-repeat: no-repeat;
}
*/

/* Sample app specific styles */
#title {
  font-size: 150%;
  line-height: 120%;
  display: block;
  text-align: center;
  margin: 2% auto 0 auto;
}

.background-none {
  background-image: none !important;
  background-color: transparent !important;
}

.description {
  text-align: center;
  display: block;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  padding: 8px 0px;
}

#testPlayer {
  z-index: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1280px;
  height: 720px;
}

#playerButtons {
  z-index: 1;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  text-align: center;
  padding: 8px;
  color: #000000;
}

#playerButtons .button {
  width: 75px;
  padding: 10px;
  text-align: center;
  margin: 5px;
}

/* Display & Visibility Helpers */
.display-none {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

#progressBar {
  margin-top: 10%;
  width: 50%;
  margin-left: 25%;
  min-height: 10px;
  background: #070;
}

#progressBar_left {
  min-height: 10px;
  background: #700;
}

#progressBar_inner {
  width: 10px;
  min-height: 10px;
  background: #777;
  position: relative;
}