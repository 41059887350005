#tutorial {
    #loading-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center; 
        z-index: 10000;
    }

    #youtube-video-frame {
        width: 100vw;
        height: 85vh;
        z-index: 1;
    }

    #action-container {
        height: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;

        .button {
            box-shadow: none;
            border-radius: 50%;
            margin-left: 1.5vw;
            margin-right: 1.5vw;
            width: 9vh;
            height: 9vh;

            .image {
                img {
                    width: 5vh;
                    height: 5vh;
                    padding: 2vh;
                }
            }
        }
    }
}
