.qr-code {
    .qr > img {
      width: 7em;
      height: 7em;
    }

    .qr-label {
      text-align: center;
      padding-top: 0.1em;
      display: inline-block;
    }

    .qr-type {
      width: 2em;
      height: 2em;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      background-repeat: no-repeat;
      background-size: contain;

      &.qr-type-ios {
        transition: 0.4s;
        -webkit-transition: 0.4s;
        background-image: url('components/widgets/qr-code/images/ios.png');
      }
  
      &.qr-type-android {
        background-image: url('components/widgets/qr-code/images/android.png');
      }
    }
}

.dark #app .qr-code {
  .qr-type {
    &.qr-type-ios {
      background-image: url('components/widgets/qr-code/images/ios-dark.png');
    }
  }
}