@import "colors";

#static-loading-screen {
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: $wvcBlue;
    position: absolute;
    top: 0;
    left: 0;

    #app-load-error-message {
        font-size: 2em;
        padding: 3em;
    }

    .loading-bars {
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        bottom: 2em;
        left: 0;
        right: 0;
    }

    p {
        text-align: center;
        font-size: 2.3em;
        font-weight: bold;
        margin: 1.7em 0;
        padding: 0 4.2em;
    }
}