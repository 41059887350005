@import "../../../colors";

#app {
    .get-wvc {
        margin-top: 2em;
        text-align: center;
        white-space: nowrap;
        
        .qr-code {
            margin-left: 1em;
            margin-right: 1em;
        }

        #android-container,
        #ios-container,
        #dont-have {
            display: inline-block;
            white-space: normal;
        }

        #dont-have {
            vertical-align: top;
            text-align: left;
            padding-right: 5em;
            max-width: 40em;

            &>.label, #or-go-to {
                display: block;
                text-align: left;
                max-width: 40em;
                margin-left: 0.5em;
                line-height: 1.5em;
            }

            #dont-have-or-go-to {
                padding-right: 0.4em;
            }

            #dont-have-title {
                margin-left: 0.3em;
                line-height: 1.3em;
                font-size: 1.5em;
                color: #2196f3;
                transition: 0.4s;
                -webkit-transition: 0.4s;
            }

            #help-button {
                margin-top: 1em;
                display: inline-block;
    
                .label {
                    margin-left: 0;
                }
            }
        }
    }
}

.dark #app .get-wvc {
        #dont-have {
            #dont-have-title {
                color: $textColor-dark;
                font-weight: bold;
            }
        }
}