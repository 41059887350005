@import './scrub-bar';
@import './subtitles';

/*
#app.rootwidget.video-player {
  text-align: left;

  #main {
    margin: 0;
    padding: 0;
    max-width: none;
  }
}
*/

#app.video-player {
  background-color: #000000 !important;
}

#mediaPlayerVideo {
  object-fit: contain;
}

#video-player {
  height: 100%;
  position: relative;
  overflow: hidden;

  #audio-image_img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  #buffering {
    position: absolute;
    left: 2.5em;
    bottom: 2em;

    & > div {
      box-shadow: 0 0 1em 0.2em #000;
    }
  }

  #control-stack {
    position: absolute;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0,0,0,.5) 35%, rgba(0,0,0,1));
    padding-bottom: 1em;
    padding-top: 2em;

    #scrub-container {
      .label {
        font-size: 2em;
        color: #FFF;
        display: inline-block;
        padding-left: 0.5em;
        padding-right: 0.5em;
        vertical-align: middle;
      }
    }

    #action-container {

      .button {
        box-shadow: none;
        border-radius: 50%;
        margin-left: 1em;
        margin-right: 1em;

        &.buttonDisabled {
          opacity: 0;
        }

        .image img {
          width: 3em;
          height: 3em;
          padding: 1em;
        }
      }
    }
  }

  #scrub-bar, #scrub-bar_buffer, #scrub-bar_left, #scrub-bar_slider {
    pointer-events: none;
  }
}