.carouselmask {
    overflow: hidden;

    .carouselwidgetstrip {
        position: relative;
        white-space: nowrap;

        &.vertical>.carouselItem {
            display: block;
        }

        &.horizontal>.carouselItem {
            display: inline-block;
        }

    }

    &.overflow-left {
        position: relative;

        &::before {
            display: inline-block;
            content: " ";
            width: 50px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(90deg, rgba(128, 128, 128, 0.3), rgba(0, 0, 0, 0));
            z-index: 1;
        }
    }

    &.overflow-right {
        position: relative;

        &::after {
            display: block;
            content: " ";
            width: 50px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(128, 128, 128, 0.3));
            z-index: 1;
        }
    }

    /*
    #carouselComponent {
        position: relative;

        & > .carouselmask {
            margin-left: auto;
            margin-right: auto;
        }
    }

    #carouselComponent

    #carouselComponent>.description {
        position: static;
    }

    .carouselwidgetstrip>.button {
        margin: 0;
        width: 200px;
        padding: 32px;
    }

    .carouselwidgetstrip.vertical>.button {
        height: 200px;
    }
    */
}