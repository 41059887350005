#app {
    #back-button {
        padding: 0.5em;
        position: absolute;
        left: 0;
        top: 0;
        width: 2em;
        height: 2em;
        z-index: 1000;
        background-image: url('components/widgets/back-button/images/exit.png');
        background-repeat: no-repeat;
        background-size: contain;

        &.buttonFocussed {
          background-image: url('components/widgets/back-button/images/exit-white.png');
        }
    }
}

.dark #app #back-button {
    background-image: url('components/widgets/back-button/images/exit-white.png');
}