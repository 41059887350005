#image-player {
  width: 100%;
  height: 100%;

  #main-image_img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  #loading {
    position: absolute;
    left: 2.5em;
    bottom: 2em;
    z-index: 1000;

    & > div {
      box-shadow: 0 0 1em 0.2em #000;
    }
  }

  #back-button {
    display: none;
  }
    
  #action-container {
    position: absolute;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .5) 35%, rgba(0, 0, 0, 1));
    padding-bottom: 1em;
    padding-top: 2em;
    
    .button {
      box-shadow: none;
      border-radius: 50%;
      margin-left: 1em;
      margin-right: 1em;

      &.buttonDisabled {
        opacity: 0.5;
        background-color: #232323;
      }
    
      .image img {
        width: 3em;
        height: 3em;
        padding: 1em;
      }
    }
  }
}