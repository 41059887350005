@import "../../../colors";

.switch {
  font-size: 2.5em;
  position: relative;
  display: inline-block;
  width: 2em;
  height: 1em;
  border-radius: 1em;
  border-width: 0.15em;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &.switchFocussed {
    border-style: solid;
    border-color: $wvcYellow;
    transform: scale(1.3);
    //box-shadow: 0 0 0.58em $wvcYellow, 0 0 0.29em $wvcYellow, 0 0 0.14em $wvcYellow, 0 0 0.09em $wvcYellow;
  }
  
  .switchSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $wvcMedGrey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 1em;

    
    &:before {
      position: absolute;
      content: "";
      height: 0.76em;
      width: 0.76em;
      left: 0.117em;
      bottom: 0.117em;
      background-color: $wvcWhite;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  &.switchOn {
    .switchSlider {
      background-color: $wvcBlue;

      &:before {
        -webkit-transform: translateX(1em);
        -ms-transform: translateX(1em);
        transform: translateX(1em);
      }
    }
  }
}

.dark .switch {
  &.focus {
    border-color: $wvcYellow-dark;
  }

  &.switchOn {
    .switchSlider {
      background-color: $wvcBlue-dark;
    }
  }
}
